import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 21" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 21</h1>
      <p>Hei mammaen min!</p>
      <p>I dag er en spennende dag! Jeg leste det i Aftenposten i går, men visste du at det er noe som heter vinterjevndøgn i dag? Noen kaller det også vintersolverv. Det er rett og slett i dag at jorda er lengst vekk fra sola i løpet av hele året. Solsystemet er utrolig kult altså! Jeg tror jeg har arvet min fasinasjon for det fra deg, i tillegg til alle mine andre fantastiske egenskaper :)</p>
      <p>Om du spiser en C-vitamin (som trengs nå som sola er så langt borte) kl 10:02 i dag, så gjør du det akkurat idét sola snur!</p>
      <p>Merkverdig hvor mye jeg lærer bort til deg. Kanskje jeg også skal bli lærer? Det er uansett et supert yrke! Tihihihihihii!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
